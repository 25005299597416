<template>
  <b-card>
    <!-- about -->
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">Email</h5>
      <b-card-text>
        {{ userData.email }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
